<template>
  <div class="home">
    <div style="width: 640px;margin: 0 auto;position: relative;z-index: 5">
        <div style="width: 100%;background: #FFFFFF;text-align: center">
          <img src="@/assets/Untitled.jpg" style="border-radius: 8px;margin-top: 12px;width: 40%">
        </div>
        <div class="m7w29c">
          <div class="RVEQke"></div>
          <div class="LgNcQe">VVL Trading Plan 📊 Application Form -F（reply）</div>
<!--          <div class="QIC90c">-->
<!--            <a href="https://vvlfinancial.com/" target="_blank">Founded in 2023 and headquartered in New York City, VVL Financial Management, Inc.-->
<!--              is an investment management and financial services firm that is part of Apollo Global Management.</a>-->
<!--          </div>-->
          <div style="margin: 10px 0;">
            <a href="https://vvlfinancial.com/storm-plan/" target="_blank">Website</a>⬅⬅⬅⬅Click to view Storm Plan details
          </div>
          <div v-if="!isSubmit">
            <div style="margin-top: 10px">You have submitted it successfully, please stay tuned</div>
            <div style="margin-top: 15px;font-size: 14px"><a href="https://vvlfinancial002.com/">Fill out another reply</a></div>
          </div>
          <div v-if="isSubmit">
            <div class="QIC90c">
              <a href="https://vvlfinancial.com/" target="_blank">Dear VVL user, please fill in your information truthfully so that the system can review it for you as soon as possible.</a>
            </div>
            <div style="color: #5f6368;font-size: 16px;margin: 10px 0;border-top: 1px solid rgb(218,220,224);border-bottom: 1px solid rgb(218,220,224);padding: 10px 0;">
              Current number of remaining forms
              <div style="display: flex;margin-top: 8px">
                <img src="@/assets/form_num.svg" style="margin-top: 6px">
                <span style="font-size: 18px;margin-top: 10px;font-weight: 600">{{submitNumber>0 ? submitNumber : 'The allowance is insufficient'}}</span>
              </div>
            </div>
            <div class="md0UAd">
              * Indicate must fill in
            </div>
          </div>
        </div>
      <div v-if="isSubmit">
        <div :class="{'Qr7Oae_active': mustName, 'Qr7Oae': !mustName}">
          <div class="z12JJ">
            <span class="M7eMe">Name</span>
            <span class="vnumgf" id="i4" aria-label="This issue must be filled out"> *</span>
          </div>
          <div class="k3kHxc">
            <div class="aXBtI">
              <div class="aCsJod">
                <input
                    type="text"
                    v-model="form.name1"
                    @focus="setFocus('name1', true)"
                    @blur="setFocus('name1', false);watchFileName(1)"
                    class="whsOnd zHQkBf"
                    placeholder="Your answer"
                >
                <div class="i9lrp"></div>
                <div class="i9lrp_active" :class="{ 'focused': focused.name1 }"></div>
                <div class="i9lrp_error" v-if="mustName"></div>
              </div>
              <div class="RHiWt" v-if="mustName">
                <div style="display: flex">
                  <img src="@/assets/error.png" style="width: 20px;height: 20px">
                  <span style="margin-top: 3px;margin-left: 15px">This issue must be filled out</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="{'Qr7Oae_active': mustTel_number, 'Qr7Oae': !mustTel_number}">
          <div class="z12JJ">
            <span class="M7eMe">TEL  Number</span>
            <span class="vnumgf" id="i4" aria-label="This issue must be filled out"> *</span>
          </div>
          <div class="k3kHxc">
            <div class="aXBtI">
              <div class="aCsJod">
                <input
                    type="text"
                    v-model="form.tel_number"
                    @focus="setFocus('tel_number', true)"
                    @blur="setFocus('tel_number', false);watchFileName(2)"
                    class="whsOnd zHQkBf"
                    placeholder="Your answer"
                >
                <div class="i9lrp"></div>
                <div class="i9lrp_active" :class="{ 'focused': focused.tel_number }"></div>
                <div class="i9lrp_error" v-if="mustTel_number"></div>
              </div>
              <div class="RHiWt" v-if="mustTel_number">
                <div style="display: flex">
                  <img src="@/assets/error.png" style="width: 20px;height: 20px">
                  <span style="margin-top: 3px;margin-left: 15px">This issue must be filled out</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="Qr7Oae">
          <div class="z12JJ">
          <span class="M7eMe">
            Email（Please fill in your email address so that we can inform you of the review progress in time）
          </span>
          </div>
          <div class="k3kHxc">
            <div class="aXBtI">
              <div class="aCsJod">
                <input
                    type="text"
                    v-model="form.email"
                    @focus="setFocus('email ', true)"
                    @blur="setFocus('email ', false)"
                    class="whsOnd zHQkBf"
                    placeholder="Your answer"
                >
                <div class="i9lrp"></div>
                <div class="i9lrp_active" :class="{ 'focused': focused.email }"></div>
              </div>
            </div>
          </div>
        </div>

        <div :class="{'Qr7Oae_active': mustAge, 'Qr7Oae': !mustAge}">
          <div class="z12JJ">
            <span class="M7eMe">Age</span>
            <span class="vnumgf" id="i4" aria-label="This issue must be filled out"> *</span>
          </div>
          <div class="k3kHxc">
            <div class="aXBtI">
              <div class="aCsJod">
                <input
                    type="number"
                    :value="ageValue"
                    @input="updateAge($event.target.valueAsNumber)"
                    @focus="setFocus('age', true)"
                    @blur="setFocus('age', false);watchFileName(3)"
                    class="whsOnd zHQkBf"
                    placeholder="Your answer"
                >
                <!--                <input-->
                <!--                    type="number"-->
                <!--                    v-model="form.age"-->
                <!--                    @focus="setFocus('age', true)"-->
                <!--                    @blur="setFocus('age', false);watchFileName(3)"-->
                <!--                    class="whsOnd zHQkBf"-->
                <!--                    placeholder="Your answer"-->
                <!--                >-->
                <div class="i9lrp"></div>
                <div class="i9lrp_active" :class="{ 'focused': focused.age }"></div>
                <div class="i9lrp_error" v-if="mustAge"></div>
              </div>
              <div class="RHiWt" v-if="mustAge">
                <div style="display: flex">
                  <img src="@/assets/error.png" style="width: 20px;height: 20px">
                  <span style="margin-top: 3px;margin-left: 15px">This issue must be filled out</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="{'Qr7Oae_active': mustGender, 'Qr7Oae': !mustGender}">
          <div class="z12JJ">
            <span class="M7eMe">Gender</span>
            <span class="vnumgf" id="i4" aria-label="This issue must be filled out"> *</span>
          </div>
          <div class="k3kHxc">
            <div class="aXBtI">
              <div class="d7L4fc">
                <div class="radio-custom" @click="getGender('Male')">
                  <input type="radio" id="radio11" name="radioGender" value="Male" class="radio-input" />
                  <label for="radio11" class="radio-label">
                    <span class="radio-appearance"></span>
                    Male
                  </label>
                </div>
              </div>
              <div class="d7L4fc">
                <div class="radio-custom" @click="getGender('Female')">
                  <input type="radio" id="radio22" name="radioGender" value="Female" class="radio-input" />
                  <label for="radio22" class="radio-label">
                    <span class="radio-appearance"></span>
                    Female
                  </label>
                </div>
              </div>
              <!--              <div class="aCsJod">-->
              <!--                <input-->
              <!--                    type="text"-->
              <!--                    v-model="form.gender"-->
              <!--                    @focus="setFocus('gender', true)"-->
              <!--                    @blur="setFocus('gender', false);watchFileName(4)"-->
              <!--                    class="whsOnd zHQkBf"-->
              <!--                    placeholder="Your answer"-->
              <!--                >-->
              <!--                <div class="i9lrp"></div>-->
              <!--                <div class="i9lrp_active" :class="{ 'focused': focused.gender }"></div>-->
              <!--                <div class="i9lrp_error" v-if="mustGender"></div>-->
              <!--              </div>-->
              <div class="RHiWt" v-if="mustGender">
                <div style="display: flex">
                  <img src="@/assets/error.png" style="width: 20px;height: 20px">
                  <span style="margin-top: 3px;margin-left: 15px">This issue must be filled out</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="{'Qr7Oae_active': mustAmount, 'Qr7Oae': !mustAmount}">
          <div class="z12JJ">
            <span class="M7eMe">Current Stock Investment Amount</span>
            <span class="vnumgf" id="i4" aria-label="This issue must be filled out"> *</span>
          </div>
          <div class="k3kHxc">
            <div class="aXBtI">
              <div class="aCsJod">
                <input
                    type="text"
                    v-model="form.amount"
                    @focus="setFocus('amount', true)"
                    @blur="setFocus('amount', false);watchFileName(5)"
                    class="whsOnd zHQkBf"
                    placeholder="Your answer"
                >
                <div class="i9lrp"></div>
                <div class="i9lrp_active" :class="{ 'focused': focused.amount }"></div>
                <div class="i9lrp_error" v-if="mustAmount"></div>
              </div>
              <div class="RHiWt" v-if="mustAmount">
                <div style="display: flex">
                  <img src="@/assets/error.png" style="width: 20px;height: 20px">
                  <span style="margin-top: 3px;margin-left: 15px">This issue must be filled out</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="{'Qr7Oae_active': mustStyle, 'Qr7Oae': !mustStyle}">
          <div class="z12JJ">
            <span class="M7eMe">Investment Style</span>
            <span class="vnumgf" id="i4" aria-label="This issue must be filled out"> *</span>
          </div>
          <div class="k3kHxc">
            <div class="aXBtI">
              <div class="d7L4fc">
                <div class="radio-custom" @click="getStyle('Conservative')">
                  <input type="radio" id="radio1" name="radioGroup" value="option1" class="radio-input" />
                  <label for="radio1" class="radio-label">
                    <span class="radio-appearance"></span>
                    Conservative
                  </label>
                </div>
              </div>
              <div class="d7L4fc">
                <div class="radio-custom" @click="getStyle('Balanced')">
                  <input type="radio" id="radio2" name="radioGroup" value="option2" class="radio-input" />
                  <label for="radio2" class="radio-label">
                    <span class="radio-appearance"></span>
                    Balanced
                  </label>
                </div>
              </div>
              <div class="d7L4fc">
                <div class="radio-custom" @click="getStyle('Balanced')">
                  <input type="radio" id="radio3" name="radioGroup" value="option3" class="radio-input" />
                  <label for="radio3" class="radio-label">
                    <span class="radio-appearance"></span>
                    Aggressive
                  </label>
                </div>
              </div>
              <div class="RHiWt" v-if="mustStyle">
                <div style="display: flex">
                  <img src="@/assets/error.png" style="width: 20px;height: 20px">
                  <span style="margin-top: 3px;margin-left: 15px">This issue must be filled out</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="{'Qr7Oae_active': mustPlan, 'Qr7Oae': !mustPlan}">
          <div class="z12JJ">
            <span class="M7eMe">Estimated Investments Funds for the Plan 📊</span>
            <span class="vnumgf" id="i4" aria-label="This issue must be filled out"> *</span>
          </div>
          <div class="k3kHxc" style="width: 100%">
            <div class="aXBtI">
              <div class="aCsJod" style="width: 100%">
                <input
                    type="text"
                    v-model="form.plan"
                    @focus="setFocus('plan', true)"
                    @blur="setFocus('plan', false);watchFileName(7)"
                    class="whsOnd zHQkBf"
                    placeholder="Your answer"
                >
                <div class="i9lrp"></div>
                <div class="i9lrp_active" :class="{ 'focused': focused.plan }"></div>
                <div class="i9lrp_error" v-if="mustPlan"></div>
              </div>
              <div class="RHiWt" v-if="mustPlan">
                <div style="display: flex">
                  <img src="@/assets/error.png" style="width: 20px;height: 20px">
                  <span style="margin-top: 3px;margin-left: 15px">This issue must be filled out</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="{'Qr7Oae_active': mustExpected, 'Qr7Oae': !mustExpected}">
          <div class="z12JJ">
            <span class="M7eMe">Expected Return 📊</span>
            <span class="vnumgf" id="i4" aria-label="此问题为必填"> *</span>
          </div>
          <div class="k3kHxc">
            <div class="aXBtI">
              <div class="aCsJod">
                <input
                    type="text"
                    v-model="form.expected"
                    @focus="setFocus('expected ', true)"
                    @blur="setFocus('expected ', false);watchFileName(8)"
                    class="whsOnd zHQkBf"
                    placeholder="Your answer"
                >
                <div class="i9lrp"></div>
                <div class="i9lrp_active" :class="{ 'focused': focused.expected }"></div>
                <div class="i9lrp_error" v-if="mustExpected"></div>
              </div>
              <div class="RHiWt" v-if="mustExpected">
                <div style="display: flex">
                  <img src="@/assets/error.png" style="width: 20px;height: 20px">
                  <span style="margin-top: 3px;margin-left: 15px">This issue must be filled out</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="Qr7Oae">
          <div class="z12JJ">
          <span class="M7eMe">
            <span class="M7eMe">
              For your support of VVL, please make relevant suggestions for VVL. Our company will adopt the valuable suggestions of every user.<br>
              You can also send an email directly to the email address below⬇⬇⬇<br>
              &nbsp;support@vvl-financial.net<br>
            </span>
          </span>
          </div>
          <div class="k3kHxc" style="width: 100%">
            <div class="aXBtI">
              <div class="aCsJod" style="width: 100%">
                <input
                    type="text"
                    v-model="form.answer"
                    @focus="setFocus('answer ', true)"
                    @blur="setFocus('answer ', false)"
                    class="whsOnd zHQkBf"
                    placeholder="Your answer"
                >
                <div class="i9lrp"></div>
                <div class="i9lrp_active" :class="{ 'focused': focused.answer }"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="DE3NNc">
          <div class="lRwqcd">
            <div class="UQuaGc QvWxOd">
              <span style="padding: 0 24px;" @click="testSubmit()">Submit</span>
            </div>
          </div>
          <div style="padding: 0 8px;color: rgb(88, 42, 41);font-size: 14px;cursor: pointer;margin-top: 8px" @click="clear=true">
            Clear form content
          </div>
        </div>
      </div>
    </div>
    <div class="clear_block" v-if="clear">
      <div class="clear_contain">
        <div style="font-size: 22px;color: #202124;margin-bottom: 15px">Do you want to clear the content from the form?</div>
        <div style="line-height: 22px">This removes the answers you entered for all questions and cannot be recovered.</div>
        <div style="display: flex;justify-content: right;margin-top: 8px">
          <div class="clear_button" @click="clear=false">cancel</div>
          <div style="width: 10px"></div>
          <div class="clear_button" @click="clearForm()">Clear form content</div>
        </div>
      </div>
    </div>

    <div :class="{'dialog': !isSuccess, 'dialog_active': isSuccess}">
      {{dialogTitle}}
    </div>

    <div :class="{'dialogError': !isError, 'dialog_active_error': isError}">
      {{dialogTitle}}
    </div>
  </div>
</template>

<script>

// import axios from 'axios'

import axios from "axios";

export default {
  name: 'HomeView',
  components: {},
  data(){
    return {
      clear:false,
      other:false,
      form: {
        name1: '',
        tel_number: '',
        age:null,
        gender:'',
        amount:'',
        style:'',
        plan:'',
        expected:'',
        answer:'',
        email:''
      },
      focused: {
        name1: false,
        tel_number: false,
        age:false,
        gender:false,
        amount:false,
        style:false,
        plan:false,
        expected:false,
        answer:false,
        email:false
      },
      mustName:false,
      mustTel_number:false,
      mustAge:false,
      mustGender:false,
      mustAmount:false,
      mustStyle:false,
      mustPlan:false,
      mustExpected:false,
      submitNumber:0,
      isSubmit:true,
      isSuccess: false,
      isError:false,
      dialogTitle:''
    }
  },
  watch:{

  },

  computed: {
    ageValue: {
      get() {
        return this.form.age || ''; // 当age为null或undefined时，返回空字符串以避免NaN
      },
      set(value) {
        // 确保设置的值是数字或者null
        this.form.age = Number.isNaN(value) ? null : value;
      },
    },
  },

  methods: {

    async getSubmitNum(){
      const res=await axios.post('https://aaadmin.vvlfinancial002.com/api/common/maxNumber')
      this.submitNumber=res.data.max_count
      console.log('submitNumber',this.submitNumber);
    },

    updateAge(value) {
      // 这里value是number类型，因为使用了valueAsNumber
      this.ageValue = value;
    },

    clearForm(){
      this.form={
        name1: '',
        tel_number: '',
        age:null,
        gender:'',
        amount:'',
        style:'',
        plan:'',
        expected:'',
        answer:'',
        email:''
      }
      this.clear=false
      this.$router.go(0)
    },

    getStyle(val){
      this.form.style=val
      if(this.form.style!==''){
        this.mustStyle=false
      }else {
        this.mustStyle=true
      }
    },

    getGender(val){
      this.form.gender=val
      if(this.form.gender!==''){
        this.mustGender=false
      }else {
        this.mustGender=true
      }
    },

    setFocus(fieldName, isFocused) {
      this.$set(this.focused, fieldName, isFocused);
    },
    watchFileName(val) {
      const fields = {
        1: 'name1',
        2: 'tel_number',
        3: 'age',
        4: 'gender',
        5: 'amount',
        6: 'style',
        7: 'plan',
        8: 'expected',
      };
      const fieldName = fields[val];
      const isEmpty = this.form[fieldName] === '';

      if (fieldName) {
        this[`must${fieldName.charAt(0).toUpperCase()}${fieldName.slice(1)}`] = isEmpty;
      }

      if (val===1&&this.form.name1===''){
        this.mustName=true
      }else {
        this.mustName=false
      }
      if (val===3&&this.form.age===null){
        this.mustAge=true
      }else {
        this.mustAge=false
      }
    },

    testSubmit(){
      if(this.other===false){
        this.submit();
      }else if(this.other===true){
        this.isError=true
        this.dialogTitle='Repetitive submission is prohibited'
        setTimeout(()=>{
          this.isError=false
        },2000)
      }
    },

    submit() {
      this.other=true
      setTimeout(()=>{
        this.other=false
      },5000)
      // 初始化一个标志位，用于判断所有必填项是否都已填写
      let isValid = true;

      // 检查name字段
      if (this.form.name1 === '') {
        this.mustName = true;
        isValid = false;
      } else {
        this.mustName = false; // 如果已填写，则重置标志位
      }

      // 检查tel_number字段
      if (this.form.tel_number === '') {
        this.mustTel_number = true;
        isValid = false;
      } else {
        this.mustTel_number = false; // 如果已填写，则重置标志位
      }

      // 检查age字段
      if (this.form.age === null) {
        this.mustAge = true;
        isValid = false;
      } else {
        this.mustAge = false; // 如果已填写，则重置标志位
      }

      if (this.form.gender === '') {
        this.mustGender = true; // 假设你有一个mustGender标志位来显示gender是否必须填写
        isValid = false;
      } else {
        this.mustGender = false; // 如果已填写，则重置标志位
      }

      // 检查amount字段（假设amount是必填项）
      if (this.form.amount === '') {
        this.mustAmount = true; // 假设你有一个mustAmount标志位
        isValid = false;
      } else {
        this.mustAmount = false; // 如果已填写，则重置标志位
      }

      // 检查style字段（根据需求，style可能不是必填项，但这里假设它是）
      if (this.form.style === '') {
        this.mustStyle = true; // 假设你有一个mustStyle标志位
        isValid = false;
      } else {
        this.mustStyle = false; // 如果已填写，则重置标志位
      }

      if (this.form.plan === '') {
        this.mustPlan = true; // 假设你有一个mustPlan标志位
        isValid = false;
      } else {
        this.mustPlan = false; // 如果已填写，则重置标志位
      }

      if (this.form.expected === '') {
        this.mustExpected = true; // 假设你有一个mustPlan标志位
        isValid = false;
      } else {
        this.mustExpected = false; // 如果已填写，则重置标志位
      }

      // 如果所有必填项都已填写，则进行接口请求
      if (isValid && this.submitNumber>0) {
        // 这里调用你的接口请求函数
        this.sendRequest();
      }else if(this.submitNumber<0){
        this.isError=true
        this.dialogTitle='The allowance is insufficient'
        setTimeout(()=>{
          this.isError=false
        },2000)
      }
    },
    sendRequest() {

      // 你的接口请求代码
      axios.post('https://aaadmin.vvlfinancial002.com/api/common/invest', this.form)
          .then(response => {
            console.log(response.data);
            // 请求成功处理
            if(response.data.code===200){
              this.isSubmit=false
              this.isSuccess=true
              this.dialogTitle='Submission success'
              setTimeout(()=>{
                this.isSuccess=false
              },2000)
            }else{
              this.isError=true
              this.dialogTitle='Submission error'
              setTimeout(()=>{
                this.isError=false
              },2000)
            }
          })
          .catch(error => {
            // 请求失败处理
            this.isError=true
            this.dialogTitle='Submission error'
            setTimeout(()=>{
              this.isError=false
            },2000)
            console.error('请求失败:', error);
          });
      console.log('Sending request...');
    }
  },
  created() {
    this.getSubmitNum()
  }
}
</script>

<style scoped>

.dialog_active_error{
  position: fixed;
  min-width: 200px;
  height: 50px;
  background: #fef0f0;;
  border: 1px solid #fde2e2;
  top: 10px;
  left: 40%;
  z-index: 999;
  border-radius: 6px;
  line-height: 50px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  transition: all 1s;
  padding: 0 20px;
}

.dialogError{
  position: fixed;
  min-width: 200px;
  height: 50px;
  background: #fef0f0;
  border: 1px solid #fde2e2;
  top: -60px;
  left: 40%;
  z-index: 999;
  border-radius: 6px;
  line-height: 50px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  transition: all 1s;
  padding: 0 20px;
}

.dialog_active{
  position: fixed;
  min-width: 200px;
  height: 50px;
  background: #f6ffed;
  border: 1px solid #b7eb8f;
  top: 10px;
  left: 40%;
  z-index: 999;
  border-radius: 6px;
  line-height: 50px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  transition: all 1s;
  padding: 0 20px;
}

.dialog{
  position: fixed;
  min-width: 200px;
  height: 50px;
  background: #f6ffed;
  border: 1px solid #b7eb8f;
  top: -60px;
  left: 40%;
  z-index: 999;
  border-radius: 6px;
  line-height: 50px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.88);
  transition: all 1s;
  padding: 0 20px;
}

.sUXvCd{
  font-family: "Product Sans", Arial, Helvetica, sans-serif;
  font-size: 22.1px;
  position: relative;
  top: -6px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.clear_button{
  color: #5f6368;
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.clear_button:hover{
  background: #f9f9f9;
}

.clear_contain {
  position: absolute;
  top: 35%;
  left: 40%;
  width: 330px;
  height: 145px;
  padding: 26px;
  background: #FFFFFF;
  border-radius: 10px;
}

.clear_block {
  position: fixed;
  background: rgba(123, 122, 122, 0.6);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
}

.radio-custom {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.radio-custom input[type="radio"] {
  display: none; /* 隐藏原生的radio按钮 */
}

.radio-label {
  display: block;
  position: relative;
  padding-left: 36px; /* 为自定义radio留出足够的空间 */
  user-select: none; /* 防止文本被选中 */
}

.radio-appearance {
  content: '';
  display: inline-block;
  width: 18px; /* 外圈直径 */
  height: 18px;
  border: 2px solid #ccc; /* 默认的外圈颜色 */
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent; /* 背景色透明 */
  transition: border-color 0.3s, box-shadow 0.3s;
}

.radio-custom input[type="radio"]:checked + .radio-label .radio-appearance {
  border-color: #582a29; /* 选中时的外圈颜色 */
}

.radio-custom input[type="radio"]:checked + .radio-label .radio-appearance::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px; /* 内圈直径 */
  height: 12px;
  border-radius: 50%;
  background-color: #582a29; /* 选中时的内圈颜色 */
}

.radio-custom input[type="radio"]:focus + .radio-label .radio-appearance,
.radio-label:hover .radio-appearance {
  box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1); /* 触摸或悬停时的淡灰色光圈 */
}

.radio-custom input[type="radio"]:checked:focus + .radio-label .radio-appearance,
.radio-custom input[type="radio"]:checked + .radio-label:hover .radio-appearance {
  box-shadow: 0 0 0 8px rgba(173, 161, 148, 0.3); /* 选中且悬停时的棕色光圈 */
  background: rgba(173, 161, 148, 0.3);
}

.d7L4fc{
  margin: 0 0 22px 0;
}

.QvWxOd {
  background-color: rgb(88, 42, 41);
  color: #FFFFFF;
}

.UQuaGc{
  -webkit-transition: box-shadow 280ms cubic-bezier(0.4,0,0.2,1);
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none;
  transition: background .2s .1s;
  border: 0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-family: "Google Sans", Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: .25px;
  line-height: 36px;
  text-decoration: none;
  text-transform: none;
  min-width: auto;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  z-index: 0;
}

.UQuaGc:hover{
  opacity: 0.9;
}

.lRwqcd{
  padding-bottom: 10px;
}

.DE3NNc{
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.RHiWt{
  color: rgb(217, 48, 37);
  font-family: Roboto, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: .3px;
  height: 40px;
  line-height: 15px;
}

.aXBtI{
  display: flex;
  flex-direction: column; /* 或者其他合适的布局方式，但这里不需要flex-direction，除非有其他子元素 */
  position: relative;
  top: 14px;
}

.i9lrp {
  background-color: rgba(0, 0, 0, .12);
  bottom: 22px;
  height: 1px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}

.i9lrp_error {
  background-color: rgb(217, 48, 37);
  bottom: 22px;
  height: 2px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}

.i9lrp_active {
  background-color: rgba(0, 0, 0, 0.12); /* 默认灰色 */
  height: 2px;
  left: 50%; /* 初始位置在中间 */
  position: absolute;
  bottom: 22px; /* 紧贴输入框下方 */
  transition: background-color 0.3s ease-out, width 0.3s ease-out; /* 平滑过渡效果 */
  width: 0%; /* 初始宽度为0% */
  transform: translateX(-50%); /* 将下划线中心对齐到输入框 */
}

.i9lrp_active.focused {
  background-color: brown; /* 聚焦时变为棕色 */
  width: 100%; /* 聚焦时宽度扩展为100% */
}

.zHQkBf{
  font-family: Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .2px;
  line-height: 20px;
  color: rgb(32, 33, 36);
}

.whsOnd{
  flex-shrink: 1;
  background-color: transparent;
  border: none;
  display: block;
  font: 400 16px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  height: 24px;
  line-height: 24px;
  margin: 0;
  min-width: 0%;
  outline: none;
  padding: 0;
  z-index: 0;
}

.aCsJod {
  height: 40px;
  position: relative;
  vertical-align: top;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  outline: none;
  padding-bottom: 8px;
  width: 200px;
}


.k3kHxc {
  width: 50%;
  position: relative;
}

.z12JJ{
  margin-bottom: 16px;
}

.vnumgf {
  color: rgb(217, 48, 37);
  padding-left: .25em;
}

.M7eMe{
  font-size: 12pt;
  font-family: 'docs-Roboto', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  line-height: 24px;
  color: rgb(32, 33, 36);
  font-weight: 400;
}

/*.geS5n.Jj6Lae {*/
/*  background-color: #fff;*/
/*  border-color: rgb(217, 48, 37);*/
/*}*/

.Qr7Oae{
  transition: background-color .2s cubic-bezier(0,0,.2,1);
  background-color: #fff;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px;
  page-break-inside: avoid;
  word-wrap: break-word;
}

.Qr7Oae_active{
  transition: background-color .2s cubic-bezier(0,0,.2,1);
  background-color: #fff;
  border: 1px solid rgb(217,48,37);
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px;
  page-break-inside: avoid;
  word-wrap: break-word;
}

.md0UAd {
  color: rgb(217, 48, 37);
}

.home {
  background: #f7f4f4;
  margin: 0 auto;
  position: relative;
  height: 100%;
  min-height: 100vh;
}

.RVEQke{
  background-color: rgb(88, 42, 41);
  color: rgba(255, 255, 255, 1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 10px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: calc(100% + 2px);
}

.m7w29c{
  margin-top: 12px;
  background-color: #fff;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px;
  padding-top: 22px;
  position: relative;
}

.LgNcQe{
  font-size: 24pt;
  font-family: 'docs-Roboto', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  font-weight: 400;
  color: rgb(32, 33, 36);
  line-height: 135%;
  max-width: 100%;
}

a[href] {
  color: #15c;
}

.QIC90c{
  font-size: 11pt;
  line-height: 15pt;
  letter-spacing: 0;
  font-weight: 400;
  color: rgb(32, 33, 36);
  margin-top: 12px;
  padding-bottom: 12px;
}
</style>